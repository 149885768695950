import React, { Component, lazy, Suspense } from 'react';

const VideoList = lazy(() => import('../pieces/VideoList')) 
const SideBar = lazy(() => import('../pieces/Sidebar'))
const thumbnails = require.context('../../../public/images/VideoThumbnails', true); 

export default class Videos extends Component {
  constructor(props) {
     super(props); 

     this.handleSearchChange = this.handleSearchChange.bind(this); 
     this.handleAddTag = this.handleAddTag.bind(this); 
     this.handleRemoveTag = this.handleRemoveTag.bind(this); 

     this.state = {
       search: '', 
       tags: [
         'Civil Rights', 
         'University of Alabama', 
         'Alabama', 
         'George Wallace', 
         'Poverty', 
         'Race', 
         'KKK', 
         'Ku Klux Klan', 
         'Civil War', 
         'Schoolhouse Door',
         'War', 
         'Writer', 
         'Tuscaloosa', 
         'Mental Health', 
         'Confederacy',
         'Racism',  
         'Governor', 
         'Corruption', 
         'Volunteers', 
         'Ghosts', 
         'Mob', 
         'Voting', 
         'Coal Mines', 
         'Gambling', 
         'Gangsters', 
         'Religion', 
         'Journalism',
         'Labor', 
         'Politics', 
         'Unions',  
         'Reconstruction', 
         'WW2',
         'Holocaust',
         'Germany', 
         'Nazi', 
         'Death Camps', 
         'Ghosts', 
         'Education', 
         'Immigration', 
         'Black Belt', 
         'Emmet Till', 
         'Rose Bowl', 
         'Textile Mills', 
         'First Lady', 
         'Doug Jones', 
         'Racial Justice', 
         'John Kennedy', 
         'Redemption', 
         'Phenix City', 
         'Literature',
         'Alabama Court System', 
         'Piano', 
         'Music', 
         'Blues',
         'Jazz', 
         'Lynching', 
         'White Citizen\'s Council', 
         'Payday Loans', 
         'Habitat for Humanity',
         'Michael Donnel', 
         'Gay Rights', 
         'LGBT', 
         'LGBTQ', 
         'Not Child Safe', 
         'Jimmy Lee Jackson', 
         'Marion', 
         'Blind', 
         'Deaf', 
         'Talladega', 
         'School', 
         'Tornado', 
         'W.C. Handy', 
         'Creek Indians', 
         'Tallapoosa River', 
         'Andrew Jackson', 
         'Native Americans', 
         'Archaeology', 
         'Progressive Movement', 
         'The Black Vote', 
         '1901 Constitution', 
         'Yellow', 
         'Montgomery', 
         'Johnny Carr', 
         'Prisons', 
         'Tutweiler', 
         'Hip-Hop', 
         'Meteorites', 
         'Comedy', 
         'Law', 
         'The Roaring Twenties', 
         'Navy', 
         'Hero', 
         'Steel Industry', 
         'Alabama Congressman', 
         'Progressive Politics', 
         'Mississippi', 
         'Kathryn Tucker Windham', 
         'Storytelling', 
         'Animal Rights', 
         'Cold War', 
         'Cuba', 
         'Communist', 
         'John Patterson', 
         'Women\'s Rights', 
         'Convict Labor', 
         'Martin Luther King', 
         'Barber', 
         'Historic Landmarks', 
         'Women\'s Rowing', 
         'Foster Auditorium', 
         'Medicine', 
         'Doctors', 
         'Geology', 
         'Marble', 
         'Sylacauga', 
         'Guns', 
         'Pistols', 
         '2nd Amendment', 
         'Barnard', 
         'Fossils', 
         'Huntsville', 
         'Rocket', 
         'Mars', 
         'Alexander Graham Bell', 
         'Phone', 
         'Fraternity', 
         'Sorority', 
         'Astronomy', 
         'Theater', 
         'Architecture', 
         'Historic Houses', 
         'Improvisation', 
         'Psychic', 
         'Clay County', 
         'Prisoners of War', 
         'Physics', 
         'Madame Curie', 
         'Moon Landing', 
         'Yellow Fever', 
         'Panama Canal', 
         'Lister Hill', 
         'Birmingham', 
         'Medical', 
         'Karen Thompson', 
         'Children\'s Museum', 
         'Museum', 
         'Saving Our Rivers', 
         'Rivers', 
         'Water', 
         'Ecology', 
         'Hogfarms', 
         'Environment', 
         'Wheelchair Basketball', 
         'Medieval', 
         'Costume'
       ], 
       chosenTags: [
         
       ]
     };
  }

  handleSearchChange(e) {
    // FIXME: Future problem, only load fixed amount of videos
    // for loading scalability
    // FIXME: future: only search videos when user has stopped typing for a 
    // set time
    const search = e.target.value.toLowerCase()
    if (search.length >= 2) {
      this.setState({ search })
    } else {
      this.setState({ search: '' })
    }
  }

  handleAddTag(e) {
    const tag = e.target.innerText 
    let chosenTags = this.state.chosenTags
    if (!chosenTags.includes(tag)) {
      chosenTags.push(tag)
      this.setState({ chosenTags })
    } else {
       // dont have this tag
       alert(`Tag "${tag}" already chosen!`)
    }
  }

  handleRemoveTag(e) {
    let tag
    if (e.target.tagName == "P")
      tag = e.target.innerText
    else if (e.target.tagName == "DIV")
      tag = e.target.children[0].innerText
    let chosenTags = this.state.chosenTags
    chosenTags = chosenTags.filter(e => e != tag)
    this.setState({ chosenTags })
  }

  render() {
   return (
    <div className="page-container">
      <div className="sidebar">
        <Suspense fallback={<h1></h1>}>
          <SideBar 
            chosenTags={this.state.chosenTags}
            tags={this.state.tags}
            onSearchChange={this.handleSearchChange}
            addTag={this.handleAddTag}
            removeTag={this.handleRemoveTag}
          />
        </Suspense>
      </div>
      <div className="video-container">
        <br /><br /><br />
        <Suspense fallback={<h1></h1>}>
          <VideoList 
            title="Films"
            search={this.state.search}
            chosenTags={this.state.chosenTags}
          /> 
          {
            // this.state.search == '' &&
            // <div>
            //   <div className="txt-container">
            //     <p className="txt-body">
            //      <b>Mission:</b> The Hero Next Door encourages students to discover someone from their community, from the past or the present, who has made a difference to the quality of life in their community - and to make a short documentary about what that person did.
            //      <br/><br/>
            //      Produce your own The Hero Next Door Episode
            //      Does your school have a community service requirement? The Hero Next Door is a great tool for community building. {/*To learn more about how producing a Hero Next Door movie can help students fulfill their community service requirement, click here.*/} The Hero Next Door is a great tool for character building. Many think that a hero is a rock star or an athlete. Producing their own Hero Next Door episode helps young people come to realize that there are unsung heros in every community, people who have the courage or kindness to do the right thing, people who make a difference. If your high school has a community service requirement for graduation, producing an episode of The Hero Next Door could be a great way to fulfill that requirement.
            //    </p>
            //   </div>
            //   <div className="footer">
            //     <p>The Hero Next Door™ is a registered trademark of Kristin Gilpatrick Halverson and is used herein by William Field with express authorization of the trademark owner.</p>
            //   </div>
            // </div>
          }
        </Suspense>
      </div>
    </div>
   );
  }
}
