import React, { Component } from 'react';
const images = require.context('../../../public/images/', true); 

export default class Contact extends Component {
  constructor(props) {
     super(props); 

     this.state = {

     };
  }

  render() {
   return (
     <div className="mission-container">
      <div className="background-img">
        <img src={images('./BamaTheatre.jpg')} alt="Bama Theatre Background"/>
      </div>
      <p className="contact-text txt-sub-title">If you are having problems with our website, contact <a className="standard-link" href="mailto:webmaster@lightscameraalabama.com">webmaster@lightscameraalabama.com</a>
      </p>
    </div>
   );
  }
}