import React from 'react';
import VideoLinks from '../../VideoData/VideoLinks-Min';
import { useParams } from 'react-router-dom'; 

const vimeoSettings = '?title=1&amp;byline=1&amp;portrait=1&amp;color=ff9933&amp;autoplay=0&amp;loop=0'; 
export default function VideoPage() {
    let { id } = useParams();  

    let videoInfo; 
    // FIXME: is there a faster way to match id's?
    // TODO: Integrate database for speed
    for (let i = 0; i < VideoLinks.length; i++) {
      if (id == VideoLinks[i].id) {
        videoInfo = VideoLinks[i]
      }
    }

    return(
      <div className="videopage">
        <div className="description">
        </div>
        <div className="video-player">
          <iframe className="movie" 
            src={videoInfo['src'] + vimeoSettings}
            title={videoInfo['id']}
            frameBorder="0"
            width="900"
            height="500"
            webkitallowfullscreen=""
            mozallowfullscreen=""
            allowfullscreen=""
          >
          </iframe>
          <div className="txt-container">
            <p className="txt-sub-title">{videoInfo['name']}</p>
            <p className="txt-body txt-body-left">{videoInfo['description']}</p>
          </div>
        </div>
      </div>
    );
}
