import React, { Component } from 'react';
const images = require.context('../../../public/images/', true); 

export default class Index extends Component {
  constructor(props) {
     super(props); 

     this.state = {

     };
  }

  render() {
   return (
     <div>
      <div className="background-img">
        <img src={images('./BamaTheatre.jpg')} alt="Bama Theatre Background"/>
      </div>
      <p className="motto">Empowering America to tell our stories</p>
      <a href="/"><button className="enter-theater-btn">Click Here to Enter the Theater!</button></a>
    </div>
   );
  }
}