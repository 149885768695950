import React, { Component } from 'react';
const images = require.context('../../../public/images/', true); 

export default class Studio extends Component {
  constructor(props) {
     super(props); 

     this.state = {

     };
  }

  render() {
   return (
      <div>
        <div className="top-img">
          <img src={images('./studiopic.svg')} alt="Bama Theatre Background"/>
        </div>
        <div className="txt-container">  
          <p className="txt-title">Studio</p>
          <br /><br />
          <p className="txt-sub-title">Coming soon...</p>
          <br /><br />
        </div>
      </div>
    )
  }
}