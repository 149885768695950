import React, { Component } from 'react';
const images = require.context('../../../public/images/', true); 

export default class Mission extends Component {
  constructor(props) {
     super(props); 

     this.state = {

     };
  }

  render() {
   return (
     <div className="mission-container">
      <div className="background-img">
        <img src={images('./BamaTheatre.jpg')} alt="Bama Theatre Background"/>
      </div>
      <p className="mission-text">Our Mission</p>
      <p className="mission-text-small">To empower students to make films about Alabama history</p>
    </div>
   );
  }
}