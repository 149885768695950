import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Navbar } from 'react-bootstrap'; 

import Index from './components/pages/index.component';
import Contact from './components/pages/contact.component';
import Mission from './components/pages/mission.component';
import Studio from './components/pages/studio.component';
import Videos from './components/pages/videos.component'; 
import VideoPage from './components/pages/videopage.component'; 

class App extends Component {
  constructor(props) {
    super(props)

    this.toggleBurgerMenu = this.toggleBurgerMenu.bind(this)

    this.state = {
      burgerMenuOpen: false
    }
  }

  toggleBurgerMenu() {
    var navItems = document.getElementsByClassName("nav-li")
    for (let i = 0; i < navItems.length; i++) {
      let item = navItems[i].classList
      item.remove("nav-li")
      item.add("hidden")
    }
    console.log('asdf', navItems)
    this.setState({
      burgerMenuOpen: !this.state.burgerMenuOpen
    })
  }

  render() {
    return (
      <Router>
        <div id="bootstrap-overrides" className="App">
          <br />
          <br />
          <div className="header">
            <ul id="navbar" expand="lg">
              <nav className="navbar-items">
                <li className="nav-li"><a href="/home">Home</a></li>
                <li className="nav-li"><a href="/">Films</a></li>
                {/* <li className="nav-li"><a href="/studio">Studio</a></li> */}
                <li className="nav-li"><a href="/mission">Mission</a></li>
                <li className="nav-li"><a href="/contact">Contact</a></li>
                {
                  /*
                  <div 
                    id="burger-menu"
                    onClick={this.toggleBurgerMenu}
                  >
                    <div className="burger-stripe"></div>
                    <div className="burger-stripe"></div>
                    <div className="burger-stripe"></div>
                  </div>
                  */
                }
                
              </nav>
            </ul>
          </div>
          <Switch>
            
            <Route exact path="/" component={ Videos }/>
            {/* <Route exact path="/studio" component={ Studio }/> */}
            <Route exact path="/mission" component={ Mission }/>
            <Route exact path="/contact" component={ Contact }/>
            <Route exact path="/home" component={ Index }/>    
            <Route path="/:id" component={ VideoPage } />        
          </Switch>
          <div className="footer">
            <p>
              ©2015 The University of Alabama, All Rights Reserved<br/>
              All Materials Used Herein are for Educational Purposes Only
            </p>
          </div>
        </div>
      </Router>
    ); 
  }
}

export default App;
